import React from 'react';

function News({ news, className }) {
  return (
    <div className={`lastest_news ${className || ""}`}>
      <div className="row">
        {news.map((d, i, arr) => {
          if (arr.length - 1 === i) {
            return (
              <div key={d.title} className="col-xl-4 col-md-4 col-sm-12 news-cards mb-0">
                <a target="_blank" className="link" href={d.url}>
                  <img className="rounded image" src={d.img} />
                  <span className="date_time mb-12">{d.date}</span>
                  <h5 className="title mb-12 text-black">{d.title}</h5>
                  <p className="d-none d-md-block mb-0">{d.description}</p>
                </a>
              </div>
            )
          } else {
            return (
              <div key={d.title} className="col-xl-4 col-md-4 col-sm-12 news-cards">
                <a target="_blank" className="link" href={d.url}>
                  <img className="rounded image" src={d.img} />
                  <span className="date_time mb-12">{d.date}</span>
                  <h5 className="title mb-12 text-black">{d.title}</h5>
                  <p className="d-none d-md-block">{d.description}</p>
                </a>
              </div>
            )
          }
        })}
      </div>
    </div >
  );
}

export default News;