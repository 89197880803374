import React, { useEffect, useRef } from 'react'

import close from '../../assets/images/icons/close-white.svg';

export default ({ open, autoplay, onClose, video }) => {
  const videoElement = useRef(null);

  const closeFn = () => {
    videoElement.current.currentTime = 0;
    videoElement.current.pause();
    onClose();
  };

  const _onHeaderClick = (event) => {
    if(event.target === event.currentTarget) {
      closeFn()
    }
 }

  useEffect(() => {
    const escapeListener = (event) => {
      if (event.key === 'Escape') {
        closeFn();
      }
    }

    window.addEventListener('keydown', escapeListener);

    return () => {
      window.removeEventListener('keydown', escapeListener);
    }
  })

  useEffect(() => {
    if (open && autoplay) {
      videoElement.current.play();
    }
  }, [open])

  return(
    <div className={`video-player ${open ? '-open' : ''}`} onClick={_onHeaderClick}>
      <div className="container">
        <button
          className="button"
          onClick={() => closeFn()}
        >
          <img src={close} className="icon" alt="close" />
          <div className="text">Close</div>
        </button>
        <video controls className="video" ref={videoElement}>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}
