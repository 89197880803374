import { Link } from 'gatsby';
import React from 'react';
import icon from "../../assets/images/icons/forward.svg";
import News from './News';

import thumb1 from '../../assets/images/news/20220512-thumbnail.jpg';
import thumb2 from '../../assets/images/news/thumb-4.jpg';
import thumb3 from '../../assets/images/news/thumb-5.jpg';

function LatestNews({ className }) {

  const news = [
    {
      img: thumb1,
      date: 'MAY 12, 2022',
      title: 'LawAdvisor Secures Backing from Legal Heavyweights with Seed Round ',
      description: 'Legaltech specialist’s investors include leading legal and technology innovators from Silicon Valley, including Microsoft and Gilead Sciences. Creates advisory panel to help scale and seize opportunities.',
      url: '/news/lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round',
    },
    {
      img: thumb2,
      date: 'MAY 12, 2022',
      title: 'LawAdvisor Raises $5M Investment, With Former Orrick CEO, Silicon Valley Funders\' Support',
      description: 'LawAdvisor, which released two products in 2021 designed with input from Barclays and a host of law firms, will bring former Orrick CEO and chairman Ralph Baxter and other investors on as advisers.',
      url: 'https://www.law.com/legaltechnews/2022/05/11/lawadvisor-raises-5-million-investment-with-former-orrick-ceo-silicon-valley-funders-support/'
    },
    {
      img: thumb3,
      date: 'MAY 12, 2022',
      title: 'Melbourne-founded LawAdvisor secures $7m in backing after raising the bar with new technology',
      description: 'Melbourne-founded Legal-tech startup LawAdvisor Ventures has raised $7.18 million from backers, including Google Maps founder Lars Rasmussen...',
      url: 'https://www.businessnewsaustralia.com/articles/lawadvisor-secures--7m-in-backing-after-raising-the-bar-with-new-technology.html'
    },
  ];

  return (
    <div id="news" className={`widget lastest_news ${className || 'fit-width'}`}>
      <div className="row ml-0 mb-2 mb-md-5">
        <div className="col-12 pl-0">
          <h3 className="float-left lastest_news_title">Latest news</h3>
          <Link to="news" className="btn btn-sm btn-outline-secondary nohover float-right d-none d-sm-block custom-btn">All news <img src={icon} />
          </Link>
        </div>
      </div>
      <News news={news} />
      <div className="row ml-0 d-md-none d-lg-none d-xl-none ">
        <div className="col-12 pl-0">
          <Link to="news" className="btn btn-sm btn-outline-secondary custom-btn">All news <img src={icon} /></Link>
        </div>
      </div>
    </div>
  );
}

export default LatestNews;
