/* eslint-disable max-len, react/jsx-one-expression-per-line */
import React, { useState } from 'react';

import Layout from '../../components/layout/Layout';
import LatestNews from '../../components/news/LatestNews';
import image4 from '../../assets/images/news/investors-cover.jpg';
import play from '../../assets/images/icons/play.svg';
import image3 from '../../assets/images/news/fib-video-preview.jpg';
import VideoPlayer from '../../components/common/VideoPlayer';
import video from '../../assets/videos/fibonacci-home.mp4';

import ralph from '../../assets/images/learn/speakers/ralph.png';
import gary from '../../assets/images/learn/speakers/gary.png';
import ben from '../../assets/images/investors/ben.png';
import brennan from '../../assets/images/brennan-ong.jpeg';

function TransformingMulti() {
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  return (
    <Layout
      title="News about us"
      description="LawAdvisor was founded in 2015 in Melbourne, Australia with a fearless and ambitious belief: that everybody should have access to justice."
      image="/metaimages/la-barclays.jpg"
    >
      <VideoPlayer
        open={videoPlayerOpen}
        onClose={() => setVideoPlayerOpen(false)}
        video={video}
      />
      <div className="articles-section">
        <div className="fit-width titleSection">
          <h2>LawAdvisor Secures Backing from Legal Heavyweights with Seed Round</h2>
          <span className="mb-0 text-muted">May 12, 2022</span>
        </div>
        <div>
          <img
            src={image4}
            className="full-width"
            alt="logo"
          />
        </div>
        <div className="fit-width contentSection">
          <p>
            <ul>
              <li style={{ fontStyle: 'italic' }}>Legaltech specialist’s investors include leading legal and technology innovators from Silicon Valley, including Microsoft and Gilead Sciences</li>
              <li style={{ fontStyle: 'italic' }}>Creates advisory panel to help scale and seize opportunities</li>
            </ul>
          </p>
          <p>
            <b>London, UK - May 12th, 2022</b>
            {' '}
            - Today, legal technology specialist <a href="https://lawadvisor.ventures/">LawAdvisor Ventures</a> has announced that it has secured USD$5 million of investment across its portfolio of ventures in a seed round involving some of the legal industry’s most influential thinkers. The capital injection includes investment from <a href="http://ralphbaxter.com/">Ralph Baxter</a> (former Chairman and CEO of Orrick), Ben Davey (former CEO, Barclays Ventures), as well as leaders from Microsoft and Gilead, and will help scale the team and capitalise on opportunities to revolutionise the practice of law with the aid of technology.
          </p>
          <p>
            Early last year, LawAdvisor <a href="https://lawadvisor.ventures/news/lawadvisor-launches-new-solutions-in-collaboration-with-barclays/">launched two breakthrough new software as service (SaaS) solutions</a> for the legal profession, <a href="https://www.legaleye.com/">LegalEye</a> and <a href="https://www.fibonacci.legal/">Fibonacci</a>. These solutions – whose development was informed by a steering group of leading legal teams – are designed to  transform how law firms work with corporate in-house legal departments and the way legal matters are managed. Both use technology to enhance collaboration and communication between lawyers and other stakeholders during the course of a legal matter to boost productivity and maximise the time lawyers spend on case work rather than administrative tasks.
          </p>
          <p>
            Developed by remote teams and launched during the height of the coronavirus pandemic, LegalEye and Fibonacci are already gaining significant market traction – with the company achieving annual recurring license revenues in excess of $2 million on their release, and early multinational clients including Bird & Bird, Simmons & Simmons, Paul Hastings, Clifford Chance, Shearman & Sterling, and <a href="https://www.law.com/international-edition/2021/01/27/barclays-adds-panel-law-firms-to-new-collaborative-platform/?slreturn=20210618050315">Barclays</a>.
          </p>
          <div className="fit-width">
            <div className="video-container">
              <div
                className="video"
                role="button"
                tabIndex="-1"
                onClick={() => {
                  setVideoPlayerOpen(true);
                }}
              >
                <div
                  className="play"
                >
                  <img src={play} className="icon" alt="play icon" />
                </div>
                <img src={image3} alt="logo" />
              </div>
            </div>
          </div>
          <p>
            As a result of this early traction, LawAdvisor opened up a strategic investment round to accelerate efforts to use its technology to solve the real-world challenges the legal profession is facing.
          </p>
          <p>
            <b>Ralph Baxter</b>
            , who since leaving his role as chairman and CEO of Orrick has engaged with a number of successful legal technology companies, sitting on boards of Intapp, Lex Machina, and LegalZoom, believes LawAdvisor has the capacity to revolutionize the way lawyers work with clients. Commenting on the investment, Baxter said:
          </p>
        </div>
        <div className="container-fluid px-0 mx-o">
          <div className="quote-section fit-width -simple">
            <div className="quote-container">
              <img src={ralph} alt="" className="thumbnail" />
              <div className="quotes">
                <span className="single-quote">“</span>
                <div className="multi-quote">
                  <p className="quote">
                    &#8220;We’re at a pivotal moment for legal technology. To make a difference, legal tech companies must understand the pain points facing lawyers and their clients, and fashion solutions that meaningfully address them; LawAdvisor does just that, making it an exceptional new contributor to the legal services ecosystem.  Led by a remarkable team, LawAdvisor has the potential to change the way legal service is delivered, for the benefit of everyone. I am excited to help the team realise these goals.&#8221;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fit-width contentSection">
          <p>
            <b>Gary Tully</b>
            , Head of Legal Operations at Gilead Silences and whose team won Corporate Counsel’s 2021 Best Legal Ops Team - when speaking about the success of LawAdvisor’s digital tools - noted that:
          </p>
        </div>
        <div className="container-fluid px-0 mx-o">
          <div className="quote-section fit-width -simple -center">
            <div className="quote-container">
              <img src={gary} alt="" className="thumbnail" />
              <div className="quotes">
                <span className="single-quote">“</span>
                <div className="multi-quote">
                  <p className="quote">
                    &#8220;The sheer velocity and rate of change facing today’s legal departments has created new challenges requiring new age thinking. LawAdvisor is addressing these challenges with its remarkably innovative tools. Tools that offer organizations the opportunity of achieving operational excellence.&#8221;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fit-width contentSection">
          <p>
            Investors in the seed round include some of the world’s most respected and celebrated legal and technology minds:
            <ul style={{ marginTop: 40 }}>
              <li>
                <b>Ralph Baxter</b>
                {' '}
                – Former chairman and CEO of Orrick, Founder of the Law Firm Leaders Forum, and named by the American Lawyer as one of the ‘Top 50 Big Law Innovators of the Last 50 Years’
              </li>
              <li>
                <b>Jason Barnwell</b>
                {' '}
                - General Manager and Associate General Counsel at Microsoft
              </li>
              <li>
                <b>Ben Davey</b>
                {' '}
                -  CSO of Azerion Group N.V. Previously Chief Investment Officer at EFIC1 and former CEO and Founder of Barclays Ventures.
              </li>
              <li>
                <b>Gary Tully</b>
                {' '}
                - Head of Legal Operations at Gilead Sciences  (Winner of Corporate Counsel’s 2021 LegalOps team of the Year)
              </li>
              <li>
                <b>Lars Rasmussen</b>
                {' '}
                - Founder of Google Maps and former Engineering Director at Facebook
              </li>
            </ul>
          </p>
          <div
            style={{ padding: '42.19% 0 0 0', position: 'relative' }}
          >
            <iframe
              src="https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
              }}
              title="Teaser 1 - Visionaries, Innovators, and Dreamers"
            />
          </div>
          <p>
            The investors will also form a new advisory panel aimed at helping LawAdvisor further develop its technology especially amidst the legal operations revolution, achieve its growth goals, and capitalise on the significant opportunities to reshape the way the law is practised to the benefit of all parties, be it from the small in-house legal team to the multinational law firm. The advisory panel is being joined by Steve Albrecht, General Counsel Barclays Execution Services, Christopher Grant, consultant and LawTech UK Panel member, Stéphanie Hamon, Head of Legal Operations Consulting, Norton Rose Fulbright, and David Kerr, Former CEO, Bird & Bird.
          </p>
          <p>
            <b>Ben Davey</b>
            , said: “The LawAdvisor team has built something that has huge potential to transform the legal profession. In an industry that has traditionally been reluctant to embrace new technology and ways of working, the collaborative approach taken to developing solutions to the industry’s trickiest issues has seen the team create something that in a short space of time could become industry standard. Combine that with the outcomes LawAdvisor’s customers see using the technology, and it’s easy to get very excited about where this business is headed.
          </p>
        </div>
        <div className="container-fluid px-0 mx-o">
          <div className="quote-section fit-width -simple -center">
            <div className="quote-container">
              <img src={ben} alt="" className="thumbnail" />
              <div className="quotes">
                <span className="single-quote">“</span>
                <div className="multi-quote">
                  <p className="quote">
                    &#8220;The calibre of the investors and advisors joining me in supporting this venture is testament to just how transformative it could be. I’m very much looking forward to being part of that journey as LawAdvisor continues to go from strength to strength.&#8221;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fit-width contentSection mt-4">
          <p style={{ color: '#1E262B' }}>
            “We founded LawAdvisor to use technology to address some of the fundamental flaws in the way the law was practiced and services were delivered. Having seen those challenges first hand, we brought in some of the leading legal teams around the world to develop solutions to those most pressing problems in collaboration with the leading thinkers in the industry,”
            {' '}
            <b>said Brennan Ong, founder and CEO of LawAdvisor Ventures.</b>
          </p>
        </div>
        <div className="container-fluid px-0 mx-o">
          <div className="quote-section fit-width -simple">
            <div className="quote-container">
              <img src={brennan} alt="" className="thumbnail" />
              <div className="quotes">
                <span className="single-quote">“</span>
                <div className="multi-quote">
                  <p className="quote">
                    &#8220;Since launching LegalEye and Fibonacci this year, we have been blown away by the reaction of our early customers and the wider market. We now want to further develop our technology so it can solve even more of the challenges today’s lawyers are facing and roll it out across more legal teams around the world. Our direct expertise of both technology and the law, coupled with our low-code, building block approach has proven to be a game-changer for legal teams. With the support of our investors and advisors, we can focus on growing our team, improving our products, and developing the business to fundamentally transform the way legal services are delivered.&#8221;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fit-width contentSection mt-4">
          <p>
            LawAdvisors’ clients are vocal advocates of the benefits of the technology, driving significant interest and demand from new clients and fuelling the company’s growth strategy. This new investment will be used to not only roll out the solutions to those new customers but further refine the capabilities and micro-services within the platforms to solve an ever-wider range of problems the legal industry is facing; ultimately facilitating what lawyers do best and allowing them to lawyer like never before.
            <br />
            <br />
            For more information about LawAdvisor, visit: <a href="https://lawadvisor.ventures/">www.lawadvisor.ventures</a>
          </p>
          <p>
            <b>About LawAdvisor Ventures</b>
            <br />
            <br />
            LawAdvisor Ventures is a London based legal-technology company developing solutions that provide the efficiencies and service delivery improvements that corporate legal departments and law firms of all sizes need to satisfy the ever-rising expectations of the businesses they serve.
            <br />
            <br />
            The company - whose backers include Lars Rasmussen, the cofounder of Google Maps and former Engineering Director for Facebook - is currently working with large multinationals and the world&apos;s leading law firms to radically reshape how lawyers work and how legal services are delivered.
          </p>
        </div>
      </div>
      <LatestNews />
    </Layout>
  );
}

export default TransformingMulti;
